import {KTSVG} from '../../../../../../../_metronic/helpers'
import {useMutation, useQueryClient} from 'react-query'
import {updateUser} from '../../core/_requests'
import {QUERIES} from '../../../../../../../_metronic/helpers'
import {useQueryResponse} from '../../core/QueryResponseProvider'
import {successToast} from '../../../../../../../_metronic/helpers/components/Toaster'
import {useLocation} from 'react-router-dom'
import {useEffect, useState} from 'react'

const UserConfirmationDelete = ({id, data, close}: any) => {
  const queryClient = useQueryClient()
  const {query} = useQueryResponse()
  const {pathname} = useLocation()
  const payload = {
    ...data,
    status: 'INACTIVE',
  }
  const [showToastMsg, setShowToastMsg] = useState(false)
  useEffect(() => {
    if (showToastMsg) {
      successToast('Deleted')
    }
  }, [showToastMsg])

  const deleteItem = useMutation(
    () =>
      updateUser(
        payload,
        pathname.includes('apps/settings/content')
          ? `content/update/${data.id}`
          : `categories/${data.id}/update`
      ),
    {
      // 💡 response of the mutation is passed to onSuccess
      onSuccess: () => {
        // ✅ update detail view directly
        queryClient.invalidateQueries([`${QUERIES.USERS_LIST}-${query}`])
        successToast('Deleted')
        setShowToastMsg(true)
        close()
      },
    }
  )
  return (
    <>
      <div className='modal fade show d-block' role='dialog' tabIndex={-1} aria-modal='true'>
        <div
          className={`modal-dialog 
         mw-650px modal-dialog-centered`}
        >
          <div className='modal-content'>
            <div className='modal-header'>
              <h5 className='modal-title'>Confirmation</h5>
              <div
                className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                data-bs-dismiss='modal'
                aria-label='Close'
                onClick={close}
              >
                <KTSVG
                  path='/media/icons/duotune/arrows/arr061.svg'
                  className='svg-icon svg-icon-2x'
                />
              </div>
            </div>
            <div id='kt_modal_add_user' className='modal-body scroll-y mx-5 mx-xl-15 my-7'>
              <div className='text-center flex justify-center'>
                Are you sure you want to delete ?
              </div>
              <div style={{textAlign: 'center'}}>
                <br />
              </div>
            </div>
            <div className='modal-footer'>
              <button
                type='reset'
                onClick={() => close()}
                className='btn btn-light me-3'
                data-kt-users-modal-action='cancel'
                // disabled={formik.isSubmitting || isUserLoading}
              >
                No
              </button>
              <button
                onClick={async () => {
                  await deleteItem.mutateAsync()
                }}
                type='submit'
                className='btn btn-danger'
                data-kt-users-modal-action='submit'
                style={{backgroundColor: '#333333'}}
              >
                <span className='indicator-label'>Yes</span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className='modal-backdrop fade show'></div>
    </>
  )
}

export {UserConfirmationDelete}
