/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useState, useRef} from 'react'
import {useMutation, useQueryClient} from 'react-query'
import {MenuComponent} from '../../../../../../../_metronic/assets/ts/components'
import {ID, KTIcon, QUERIES} from '../../../../../../../_metronic/helpers'
import {useListView} from '../../core/ListViewProvider'
import {useQueryResponse} from '../../core/QueryResponseProvider'
import {deleteUser, updateUser, createUser, getUsers} from '../../core/_requests'
import {useQueryRequest} from '../../core/QueryRequestProvider'
import {AssignHubModal} from './AssignPickUPBoy'
import GenerateQrCode from './GenerateQrCode'
import WinnerSelection from './WinnerSelection'
import {useLocation} from 'react-router-dom'
import {successToast} from '../../../../../../../_metronic/helpers/components/Toaster'
import {UserConfirmationDelete} from './UserDeleteConfirmation'

type Props = {
  id: any
  allData: any
  action: any
}

const UserActionsCell: FC<Props> = ({id, allData, action = ['edit']}) => {
  const contentRef = useRef(null)
  const {itemIdForUpdate, setItemIdForUpdate} = useListView()
  const {query, refetch} = useQueryResponse()
  const queryClient = useQueryClient()
  const {state} = useQueryRequest()
  const [showPickUpModal, setShowAssignHubModal] = useState(false)

  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])

  const openEditModal = () => {
    setItemIdForUpdate(id)
  }
  const assignPostMethod = useMutation(
    (payload: any) => createUser(payload, `smart-centre/order/assign-agent`),
    {
      // 💡 response of the mutation is passed to onSuccess
      onSuccess: () => {
        // ✅ update detail view directly
        console.log({state}, 'FROM THE STATE')
        cancel(true)
        queryClient.invalidateQueries([`return123/orders-${query}`])
        setShowAssignHubModal(false)
      },
    }
  )

  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      refetch()
    }
    setItemIdForUpdate(undefined)
  }
  const {pathname} = useLocation()
  const deleteItem = useMutation(
    () =>
      updateUser(
        {...id, status: 'INACTIVE'},
        pathname.includes('apps/settings/content')
          ? `content/update/${id.id}`
          : `categories/${id.id}/update
  `
      ),
    {
      // 💡 response of the mutation is passed to onSuccess
      onSuccess: () => {
        // ✅ update detail view directly
        queryClient.invalidateQueries([`${QUERIES.USERS_LIST}-${query}`])
        successToast(`Deleted`)
      },
    }
  )

  const showAssignHubModal = () => {
    setShowAssignHubModal(true)
  }

  const toggleStatus = useMutation(
    () =>
      updateUser(
        {},
        `user/${id.id}/status?type=${state.type}&status=${
          id.status === 'ACTIVE' ? 'INACTIVE' : 'ACTIVE'
        }`
      ),
    {
      // 💡 response of the mutation is passed to onSuccess
      onSuccess: () => {
        // ✅ update detail view directly
        queryClient.invalidateQueries([`${QUERIES.USERS_LIST}-${query}`])
      },
    }
  )
  const [showQrCode, setShowQrCode] = useState(false)
  const [showWinnerSelection, setShowWinnerSelection] = useState(false)
  const [showDeleteModal, setShowDeleteModal] = useState<any>(false)

  return (
    <>
      {showPickUpModal && (
        <AssignHubModal
          id={id}
          confirm={async (payload) => await assignPostMethod.mutateAsync(payload)}
          close={() => setShowAssignHubModal(false)}
        />
      )}
      {showDeleteModal && (
        <UserConfirmationDelete
          data={id}
          close={() => {
            successToast('Deleted')
            setShowDeleteModal(false)
          }}
        />
      )}
      {showQrCode && <GenerateQrCode data={id} close={() => setShowQrCode(false)} />}
      {showWinnerSelection && (
        <WinnerSelection data={id} close={() => setShowWinnerSelection(false)} />
      )}
      <div className='align-items-center min-w-125px'>
        {(action.includes('view') && (
          <a
            onClick={openEditModal}
            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
          >
            <KTIcon iconName='eye' className='fs-3' />
          </a>
        )) ||
          false}
        {/* {(action.includes('download') && (
          <>
            <a
              onClick={() => handleDownload()}
              className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
            >
              <KTIcon iconName='file-down' className='fs-3' />
            </a>
          </>
        )) ||
          false} */}
        {(action.includes('edit') && (
          <a
            onClick={openEditModal}
            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
          >
            <KTIcon iconName='pencil' className='fs-3' />
          </a>
        )) ||
          false}
        {action.includes('assign') && (
          <>
            {id.status && id.status === 'Pickup Assigned' ? (
              '-----'
            ) : (
              <a
                onClick={async () =>
                  allData.status &&
                  ['completed', 'pickup completed', 'Pickup Completed'].includes(
                    allData.status.toLowerCase()
                  )
                    ? ''
                    : showAssignHubModal()
                }
                className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                /*    style={{
                  cursor:
                    allData.status &&
                    ['completed', 'pickup completed', 'Pickup Completed'].includes(
                      allData.status.toLowerCase()
                    ) &&
                    'not-allowed',
                }} */
              >
                Assign Rider
              </a>
            )}
          </>
        )}
        {action.includes('delete') && action.includes('edit') && (
          <a
            // onClick={async () => await deleteItem.mutateAsync()}
            onClick={() => setShowDeleteModal(!showDeleteModal)}
            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
          >
            <KTIcon iconName='trash' className='fs-3' />
          </a>
        )}
        {(action.includes('toggle') && (
          <div className='btn btn-icon btn-active-color-primary'>
            <div className='form-switch'>
              <input
                className='form-check-input'
                type='checkbox'
                id='allowmarketing'
                defaultChecked={id.status === 'ACTIVE' ? true : false}
                onChange={async () => {
                  await toggleStatus.mutateAsync()
                }}
              />
            </div>
          </div>
        )) ||
          false}
        {(action.includes('qr') && (
          <a
            onClick={() => setShowQrCode(true)}
            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
          >
            <KTIcon iconName='code' className='fs-3' />
          </a>
        )) ||
          false}

        {(action.includes('winner') && (
          <a
            onClick={() => setShowWinnerSelection(true)}
            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
          >
            <KTIcon iconName='gift' className='fs-3' />
          </a>
        )) ||
          false}
      </div>
      {/* <div
        className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-125px py-4'
        data-kt-menu='true'
      >
        <div className='menu-item px-3'>
          <a className='menu-link px-3' onClick={openEditModal}>
            Edit
          </a>
        </div>

        <div className='menu-item px-3'>
          <a
            className='menu-link px-3'
            data-kt-users-table-filter='delete_row'
            onClick={async () => await deleteItem.mutateAsync()}
          >
            Delete
          </a>
        </div>
      </div> */}
    </>
  )
}

export {UserActionsCell}
