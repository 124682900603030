import React, {useState} from 'react'
import QRCode from 'qrcode.react'
import {KTIcon, KTSVG, generateAddress, isNotEmpty} from '../../../../../../../_metronic/helpers'
import html2pdf from 'html2pdf.js'
import html2canvas from 'html2canvas'
import UploadImage from '../../../../../../../_metronic/helpers/components/ImageUpload'
import clsx from 'clsx'
import {createUser, updateUser} from '../../core/_requests'
import {FieldArray, FormikProvider, useFormik} from 'formik'
import {useListView} from '../../core/ListViewProvider'
import {useQueryResponse} from '../../core/QueryResponseProvider'
import {useFetchCommon} from '../../../../../../../_metronic/helpers/crud-helper/useQuery'
import * as Yup from 'yup'
import {successToast} from '../../../../../../../_metronic/helpers/components/Toaster'

const editUserSchema = Yup.object().shape({
  defaultDynamic: Yup.array().of(
    Yup.object({
      categoryId: Yup.string().required('Select Customer'),
    })
  ),
})
const initialState = [{image: '', text: ''}]

const WinnerSelection = ({data, close}) => {
  const {setItemIdForUpdate} = useListView()
  const {refetch} = useQueryResponse()
  const [userForEdit] = useState<any>({
    defaultDynamic:
      (data.winners
        ? data.winners.map((x) => ({
            categoryId: x?.customerId || '',
            text: x?.customerPrize?.id || '',
            image: x?.customerPrize?.url || '',
          }))
        : data?.prizes?.length &&
          data?.prizes?.map((x) => ({
            text: x?.text || '',
            image: x?.prizeImage?.url || '',
          }))) || initialState,
  })

  const {responseData: customerList, isFetching} = useFetchCommon({
    api: 'lottery/buyers/RFL-136',
    isDropDown: true,
  })

  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      refetch()
    }
    setItemIdForUpdate(undefined)
  }
  const formik: any = useFormik({
    initialValues: userForEdit,
    validationSchema: editUserSchema,
    onSubmit: async (values, {setSubmitting}) => {
      setSubmitting(true)
      try {
        const payload = {
          winners: values.defaultDynamic.map((x, index) => {
            return {
              customerId: x.categoryId,
              customerName: customerList.filter(
                (eachCustomer) => eachCustomer.value === x.categoryId
              )[0].label,
              customerPrize: {
                id: x.text,
                url: x.image,
              },
              prizeNumber: index + 1,
            }
          }),
        }
        console.log({values, payload})
        await updateUser(payload, `contest/update/${data.id}`)
        successToast('Modified')
        close()
      } catch (ex) {
        console.error(ex)
      } finally {
        setSubmitting(true)
        cancel(true)
      }
    },
  })

  const makeSelectDropDown = (name = '', options: any = [], disabled = false) => {
    const getNestedValue = (obj, path) => {
      const parts = path
        .replace(/\[(\d+)\]/g, '.$1') // Convert array indices to dot notation
        .split('.') // Split path into parts
      return parts.reduce(
        (acc, part) => (acc && acc[part] !== undefined ? acc[part] : undefined),
        obj
      )
    }
    const errorMessage = getNestedValue(formik.errors, name)
    return (
      <>
        <select
          disabled={disabled}
          // className='form-select form-select-solid form-select-lg'
          className={clsx(
            'form-control form-control-solid mb-3 mb-lg-0',
            {'is-invalid': formik.touched[name] && formik.errors[name]},
            {
              'is-valid': formik.touched[name] && !formik.errors[name],
            }
          )}
          {...formik.getFieldProps(name)}
        >
          <option value=''>Select One...</option>
          {options.map((eachOption, eachInd) => (
            <option key={eachInd + 1 + ''} value={eachOption.value}>
              {eachOption.label}
            </option>
          ))}
        </select>
        {errorMessage && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{errorMessage}</span>
            </div>
          </div>
        )}
      </>
    )
  }
  return (
    <>
      <div className='modal fade show d-block' role='dialog' tabIndex={-1} aria-modal='true'>
        <div
          className={`modal-dialog 
           mw-650px modal-dialog-centered`}
        >
          <div className='modal-content'>
            <form
              id='kt_modal_add_user_form'
              className='form'
              onSubmit={formik.handleSubmit}
              noValidate
            >
              <div className='modal-header'>
                <h5 className='modal-title'>Winner Selection</h5>
                <div
                  className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                  data-bs-dismiss='modal'
                  aria-label='Close'
                  onClick={close}
                >
                  <KTSVG
                    path='/media/icons/duotune/arrows/arr061.svg'
                    className='svg-icon svg-icon-2x'
                  />
                </div>
              </div>
              <div id='kt_modal_add_user' className='modal-body scroll-y mx-5 mx-xl-15 my-7'>
                <FormikProvider value={formik}>
                  <FieldArray
                    name='defaultDynamic'
                    render={(arrayHelpers) => {
                      const datas = formik.values.defaultDynamic
                      return (
                        <div className='card p-4'>
                          {datas?.map((x, index) => (
                            <div key={index + 1 + ''}>
                              <div className='row' key={index}>
                                <div className='col-2'>{index + 1 + ''}</div>
                                <div className='col-5'>
                                  <UploadImage
                                    readOnly
                                    formik={formik}
                                    label='Add Prizes'
                                    name={`defaultDynamic[${index}].image`}
                                  />
                                </div>
                                <div className='col-5'>
                                  <div className='fv-row mb-7'>
                                    <label className='required fw-bold fs-6 mb-2'>
                                      Select Winner
                                    </label>
                                    {makeSelectDropDown(
                                      `defaultDynamic[${index}].categoryId`,
                                      customerList,
                                      data?.winners?.length ? true : false
                                    )}
                                    {formik.touched.categoryId && formik.errors.categoryId && (
                                      <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block'>
                                          <span role='alert'>{formik.errors.categoryId}</span>
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                              {formik.values.defaultDynamic.length !== 1 ? <hr /> : ''}
                            </div>
                          ))}
                        </div>
                      )
                    }}
                  />
                </FormikProvider>
              </div>
              <div className='modal-footer'>
                <button
                  type='button'
                  onClick={close}
                  className='btn btn-light'
                  data-bs-dismiss='modal'
                >
                  Discard
                </button>
                {(!data?.winners?.length && (
                  <button
                    type='submit'
                    className='btn btn-primary'
                    data-kt-users-modal-action='submit'
                    disabled={formik.isSubmitting || !formik.isValid || !formik.touched}
                  >
                    <span className='indicator-label'>Submit</span>
                    {formik.isSubmitting && (
                      <span className='indicator-progress'>
                        Please wait...{' '}
                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                      </span>
                    )}
                  </button>
                )) ||
                  ''}
              </div>
            </form>
          </div>
        </div>
      </div>
      <div className='modal-backdrop fade show'></div>
    </>
  )
}

export default WinnerSelection
/* 

{
    "text": "Macbook Air",
    "image": "https://asm.blr1.digitaloceanspaces.com/images/1741157275407",
    "categoryId": "6798ae4bff7e486fb02070b1"
}

*/
